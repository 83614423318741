html, body {
  margin: 0;
}
.wrapper {
  display: flex;
}
.formBlock {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  row-gap: 1rem;
  width: 320px;
  height: fit-content;
  padding: 1rem;
}
.formPanel {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  row-gap: 0.6rem;
}
.formPanel textarea{
  resize: vertical;
  height: 300px;
}

hr {
  border: none;
  border-top: 1px dotted #ccc;
  outline: none;
  width: 100%;
}


.list {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1rem;
}

.item {
  display: flex;
  row-gap: 0.2rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1rem;
}
.item[data-complete="true"] {
  background-color: #f0f0f0;
}

.post {
  display: flex;
  flex-direction: column;
  row-gap: 0.2rem;
  width: 100%;
}
.post span:first-of-type {
  font-size: 0.76rem;
}
.post span:nth-of-type(2) {
  font-weight: bold;
}
.post > p {
	white-space: pre-wrap;
}

.generate {
  flex: 0 0 auto;
  width: 50%;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
	white-space: pre-wrap;
}
.generate p {
  margin: 0;
}